<template>
<div v-if="!link" class="py-2 px-4 rounded-3xl text-white cursor-pointer text-center w-100 font-[600] block" :class="color">
    <span class="inline-block mr-1.5" v-if="iconSvg" v-html="iconSvg"></span>
    {{text}}
</div>
<a v-else :href="link" class="py-2 px-4 rounded-3xl text-white cursor-pointer text-center w-100 font-[600] block" :class="color">
    <span class="inline-block mr-1.5" v-if="iconSvg" v-html="iconSvg"></span>
    {{text}}
</a>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    text: String,
    link: String,
    color: String,
    iconSvg: String,
})

const color = computed(() => {
    if(props.color){
        return 'bg-[' + props.color + ']'
    }else{
        return 'bg-[#2481cc]'
    }
})

</script>

<style>

</style>